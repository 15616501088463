.rec .rec-slider-container {
  @apply m-0;
}
.rec.rec.large-carousel .rec-slider-container {
  @apply overflow-visible;
}
.rec.compact-carousel,
.rec.compact-carousel .rec-carousel,
.rec.compact-carousel .rec-slider-container,
.rec.compact-carousel .rec-slider,
.rec.compact-carousel .rec-slider .rec,
.rec.compact-carousel .rec-carousel-item,
.rec.compact-carousel .rec-carousel-item .rec-item-wrapper {
  @apply w-full h-full;
  height: 100% !important;
}

/* Arrows */
.rec .rec-arrow {
  @apply absolute transition-opacity duration-300 opacity-0 cursor-pointer;
  z-index: 2;
  top: 50%;
  margin-top: -25px;
}

.rec:hover .rec-arrow {
  @apply opacity-100;
}

.rec .rec-arrow.rec-disabled {
  @apply opacity-0;
}

.rec.rec.compact-carousel .rec-arrow.rec-arrow_prev {
  @apply left-8;
}

.rec.rec.compact-carousel .rec-arrow.rec-arrow_next {
  @apply right-8;
}

.rec.rec.large-carousel .rec-arrow.rec-arrow_prev {
  left: -25px;
}

.rec.rec.large-carousel .rec-arrow.rec-arrow_next {
  right: -25px;
}

/* Dots */
.rec.compact-carousel .rec-pagination {
  @apply absolute p-0 m-0 text-left left-4 bottom-4 md:left-8 md:bottom-8;
}
.rec.large-carousel .rec-pagination {
  z-index: 1;
  @apply self-start py-2 m-0 mt-6 text-left md:mt-8;
}

.rec .rec-pagination .rec-dot {
  @apply bg-purple-300 rounded-full w-1.5 h-1.5 mr-1 cursor-pointer inline-block;
  margin: 0 5px 0 0;
  box-shadow: none;
}

.rec .rec-pagination .rec-dot.rec-dot_active {
  opacity: 1;
  @apply bg-purple-500;
}

.rec.large-carousel.rec-pagination_hide .rec-pagination {
  @apply hidden;
}
.rec.large-carousel.rec-pagination_show .rec-pagination {
  @apply flex;
}
.rec.large-carousel.rec-desktop-pagination_show .rec-pagination {
  @apply md:flex;
}
.rec.large-carousel.rec-desktop-pagination_hide .rec-pagination {
  @apply md:hidden;
}

/* Swiper */
/* Arrows */
.swiper-arrow {
  @apply absolute transition-opacity duration-300 opacity-0 cursor-pointer;
  z-index: 2;
  top: 50%;
  margin-top: -25px;
}

.carousel:hover .swiper-arrow {
  @apply opacity-100;
}

.carousel .swiper-arrow.swiper-disabled {
  @apply opacity-0;
}

.carousel.compact-carousel .swiper-arrow.swiper-arrow_prev {
  @apply left-8;
}

.carousel.compact-carousel .swiper-arrow.swiper-arrow_next {
  @apply right-8;
}

.carousel.large-carousel .swiper-arrow.swiper-arrow_prev {
  left: -25px;
}

.carousel.large-carousel .swiper-arrow.swiper-arrow_next {
  right: -25px;
}

/* Pagination */
.carousel.compact-carousel .swiper-pagination {
  @apply absolute p-0 m-0 text-left left-4 bottom-4 md:left-8 md:bottom-8;
}
.carousel.large-carousel .swiper-pagination {
  z-index: 1;
  @apply static self-start py-2 m-0 mt-6 text-left md:-mb-12;
}

.carousel .swiper-pagination .swiper-pagination-bullet {
  @apply bg-purple-400 rounded-full w-1.5 h-1.5 mr-1 cursor-pointer inline-block;
  margin: 0 5px 0 0;
  box-shadow: none;
}

.carousel
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  @apply bg-purple-500;
}

.carousel.large-carousel.swiper-pagination_hide .swiper-pagination {
  @apply hidden;
}
.carousel.large-carousel.swiper-pagination_show .swiper-pagination {
  @apply flex;
}
.carousel.large-carousel.swiper-desktop-pagination_show .swiper-pagination {
  @apply md:flex;
}
.carousel.large-carousel.swiper-desktop-pagination_hide .swiper-pagination {
  @apply md:hidden;
}


/* updated */

@media (min-width: 768px){
  .carousel.large-carousel .swiper-pagination {
      margin: 45px 0 0;
  }
}