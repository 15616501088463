@tailwind base;

/* Typography */
@layer base {
  @font-face {
    font-family: "Brown";
    src:
      url("../assets/fonts/BrownLLWeb-Regular.woff") format("woff"),
      url("../assets/fonts/BrownLLWeb-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Brown";
    src:
      url("../assets/fonts/BrownLLWeb-Bold.woff") format("woff"),
      url("../assets/fonts/BrownLLWeb-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Poppins";
    src:
      url("../assets/fonts/Poppins-Regular.woff") format("woff"),
      url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
      url("../assets/fonts/Poppins-Regular.ttf") format("truetype"),
      url("../assets/fonts/Poppins-Regular.svg#PoppinsRegular") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Poppins";
    src:
      url("../assets/fonts/Poppins-Italic.woff") format("woff"),
      url("../assets/fonts/Poppins-Italic.woff2") format("woff2"),
      url("../assets/fonts/Poppins-Italic.ttf") format("truetype"),
      url("../assets/fonts/Poppins-Italic.svg#PoppinsItalic") format("svg");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Poppins";
    src:
      url("../assets/fonts/Poppins-Medium.woff") format("woff"),
      url("../assets/fonts/Poppins-Medium.woff2") format("woff2"),
      url("../assets/fonts/Poppins-Medium.ttf") format("truetype"),
      url("../assets/fonts/Poppins-Medium.svg#PoppinsMedium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Poppins";
    src:
      url("../assets/fonts/Poppins-SemiBold.woff") format("woff"),
      url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
      url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype"),
      url("../assets/fonts/Poppins-SemiBold.svg#PoppinsSemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Poppins";
    src:
      url("../assets/fonts/Poppins-Bold.woff") format("woff"),
      url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
      url("../assets/fonts/Poppins-Bold.ttf") format("truetype"),
      url("../assets/fonts/Poppins-Bold.svg#PoppinsBold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  html {
    @apply text-base font-normal leading-5 font-poppins text-grey-900;
    height: -webkit-fill-available;
  }

  body {
    height: -webkit-fill-available;
  }

  /* If using React, set height on the root div as well */
  #root {
    height: -webkit-fill-available;
  }

  h1 {
    @apply text-4xl font-bold leading-10 md:text-5xl lg:text-6xl md:leading-13 lg:leading-15 text-grey-900;
  }
  h2 {
    @apply font-medium text-2.5xl md:text-3.5xl lg:text-4xl leading-7.5 md:leading-9 lg:leading-10 text-grey-900;
  }
  h3 {
    @apply font-medium text-2xl md:text-2.5xl lg:text-3.5xl leading-6.5 md:leading-7.5 lg:leading-9 text-grey-900;
  }
  h4 {
    @apply font-medium text-lg md:text-2xl lg:text-2.5xl leading-5 md:leading-6.5 lg:leading-7.5 text-grey-900;
  }
  h5 {
    @apply font-medium text-base md:text-lg lg:text-2xl leading-4.5 md:leading-5 lg:leading-6.5 text-grey-900;
  }
  h6 {
    @apply font-medium text-grey-900;
  }

  intro {
    @apply text-xl md:text-1.5xl leading-6.5 md:leading-7 text-grey-900;
  }

  p {
    @apply text-base leading-5 font-poppins text-grey-700;
  }

  b {
    @apply font-bold;
  }

  .large {
    @apply text-lg leading-6;
  }

  .logo-large {
    background-image: url("/images/large.svg");
    background-size: auto 162px;
    background-position: right -30px;
    background-repeat: no-repeat;
  }

  .logo-large-desktop {
    background-size: auto 337px;
    background-position: right -70px;
  }

  .product-hover {
    @apply absolute top-0 left-0 hidden w-full h-full;
  }
  .hover .product-hover {
    @apply block;
  }

  input.appearance-none::-webkit-outer-spin-button,
  input.appearance-none::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input.appearance-none[type="number"] {
    -moz-appearance: textfield;
  }

  .mobile-screen-height {
    height: 100dvh;
    overflow: hidden;
  }
  @media screen and (min-width: 768px) {
    .hero-fullscreen {
      max-height: calc(100vh - 90px);
    }
    .mobile-screen-height {
      height: 100vh;
      overflow: visible;
    }
  }

  .page-break-after {
    page-break-after: always;
  }
}

/* Tailwind components and Utilities */
@tailwind components;
@layer components {
  .phone-input {
    @apply flex-1 block w-full px-3 py-2 text-base leading-6 transition-all border rounded-md rounded-tl-none rounded-bl-none shadow-sm appearance-none text-grey-900 placeholder-grey-500 focus:outline-none disabled:cursor-not-allowed disabled:bg-grey-200 border-grey-300 focus:border-purple-500 focus:ring-purple-500;
  }
}
@tailwind utilities;

/* Custom components */
@import "./carousel.css";

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #6b7280;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #6b7280;
}

/* testimonials */

.col-count-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  orphans: 0;
  column-gap: 32px;
}
.col-count-2 > div {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.col-count-2 > div:not(:first-child) {
  margin-top: 32px;
}
@media screen and (max-width: 768px) {
  .col-count-2 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    orphans: 0;
    column-gap: 20px;
  }
}

@media (max-width: 1024px) {
  .intercom-lightweight-app-launcher {
    bottom: 82px !important;
  }
}
